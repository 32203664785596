<template>
  <div>
    <div v-for="(balance, index) of params.value" :key="index">
      {{ formatBalance(balance) }}
    </div>
  </div>
</template>

<script>
export default {
  name: "MachineBalance",
  data: () => ({
    currencies: [
      "BRL",
      "USD",
      "AUD",
      "CLP",
      "EUR",
      "SOL",
      "PYG",
      "ARS",
      "PKR",
      "JOD",
      "KES",
      "DOP",
      "HNL",
      "AED"
    ],
  }),
  methods: {
    formatBalance(value) {
      value.balance = value.balance || 0;
      return value.balance.toLocaleString("pt-BR", {
        style: "currency",
        currency: this.currencies[value.currency - 1],
        minimumFractionDigits: 2
      });
    }
  }
};
</script>
