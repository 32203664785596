<template>
  <vs-chip class="ag-grid-cell-chip success" :color="status">
    <vx-tooltip :text="$t('status.lastPing') + ' ' + lastPing" v-if="lastPing">
      <span>{{ verifyIsOnline(params.value) }}</span>
    </vx-tooltip>
    <span v-else>{{ verifyIsOnline(params.value) }}</span>
  </vs-chip>
</template>

<script>
//import moment from 'moment'
import i18n from '@/i18n/i18n'

export default {
  name: "MachineLastPing",
  data: () => ({
      status: 'success',
      lastPing: '',
  }),
  methods: {
    verifyIsOnline(value) {
      /*
      let diff =  moment.duration(moment(value).diff(moment.now()));
      this.lastPing = value ? moment(value).local().locale(localStorage.getItem('language')).calendar() : '';
      const asMinutes = diff.asMinutes();
      if(value && Math.abs(asMinutes) < 15){
        this.status = 'success';
        return i18n.t('status.online');
      } else{
        this.status = 'danger';
        return i18n.t('status.offline');
      }
      */
      if(value){
        this.status = 'success';
        return i18n.t('status.online');
      } else{
        this.status = 'danger';
        return i18n.t('status.offline');
      }
    },
  },
};
</script>
