<template>
    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}" v-if="machine">
      <feather-icon v-if="machine.active" icon="EditIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="editRecord" />
      <template v-if="$store.state.AppActiveUser.type > 0">
        <feather-icon v-if="machine.active" icon="XCircleIcon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmActivate(false)" />
        <feather-icon v-else icon="CheckCircleIcon" svgClasses="h-5 w-5 hover:text-success cursor-pointer" @click="confirmActivate(true)" />
      </template>
    </div>
    
</template>

<script>
import i18n from '../../../../i18n/i18n'
    export default {
        data:()=>({
            machine: null,
        }),
        methods: {
          editRecord() {
            const data = this.params.data;
            this.$router.push({ name: "machine-edit", params: { machine:data } })
            .catch(() => 
            { 
            })
          },
          confirmActivate(active) {
            this.$vs.dialog({
              type: 'confirm',
              color: (!active ? 'danger' : 'success'),
              title: (!active ? i18n.t('machine.actions.deActivate') : i18n.t('machine.actions.activate')),
              text: `${!active ? i18n.t('machine.messages.confirm.deActivate') : i18n.t('machine.messages.confirm.activate')} "${this.params.data.serial_number}"?` ,
              accept: (!active ? this.deActivate : this.activate),
              acceptText: (!active ? i18n.t('actions.deActivate') : i18n.t('actions.activate')),
              cancelText: i18n.t('actions.cancel')
            })
          },
          activate(){
            this.activateRecord(true);
          },
          deActivate(){
            this.activateRecord(false);
          },
          activateRecord(active) {
            var record = { id: this.params.data.id, active: active };
            
            this.$store.dispatch('httpRequest', {
              method: 'post', 
              url:'machine/edit',
              data: record
            }).then(() => {
                this.showSuccess(active);
                this.$parent.$parent.loadData();
            }).catch(error => {
                this.$vs.notify({
                    title: i18n.t('dialog.error'),
                    text: error.message,
                    color: 'danger'
                })
            });

          },
          showSuccess(active) {
            this.$vs.notify({
              color: 'success',
              title: i18n.t('dialog.success'),
              text: (!active ? i18n.t('machine.messages.success.deActivate') : i18n.t('machine.messages.success.activate'))
            })
          }
        },
        mounted() {
          this.machine = this.params.data;
        }
    }
</script>
